<template>
    <div class="uploadBox">
        <el-upload
            :disabled="mayEdit"
            :action="imgurl()"
            name="pic"
            :limit="limitNum"
            ref="uploadFile"
            list-type="picture-card"
            :file-list="showFileList"
            :on-success="handleFileSuccess"
            :on-preview="handleFilePreview"
            :on-remove="handleFileRemove"
            :on-exceed = "handleFileExceed"
        >
            <i class="el-icon-camera-solid"></i>
        </el-upload>

        <el-image-viewer
           v-if="dialogImageVisible"
           :on-close="closeViewer"
           :url-list="[dialogImageUrl]" 
        />
    </div>
</template>
<script>
    // import viewPicture from '@/components/viewPicture';
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
    export default {
        data() {
            return {
                showFileList:[], //展示图片列表
                uploadFileList:[], //上传图片列表
                previewList:[],
                dialogImageUrl:'',
                dialogImageVisible:false,
                formData:{},

                imageArr:[]
            }
        },
        props: [
            'mayEdit',
            'imageList',
            'clickFile',
            'limitNum'
        ],
        components:{
            ElImageViewer 
        },
        mounted() {
           this.init(); 
        },
        methods: {
            init(){  
                //读取商品详情轮播图数据组合
                let _this = this;
                this.formData[this.clickFile] = [];
                
                setTimeout(function(){
                    for(let image of _this.imageList){
                        _this.imageArr.push({
                            url:this.domain + image.url
                        })
                    }
                    _this.showFileList = _this.imageArr;
                },700) 
            },

            //获取接口地址
            imgurl() {
                return this.domain + "/upload/upFile"; //接口地址
            },
            //图片上传成功的函数
            handleFileSuccess(response) {
                if (parseInt(response.code) != 200) return this.$message({
                    type: 'warning',
                    message: '上传失败，请重试'
                })

                this.formData[this.clickFile] = this.imageList;

                this.formData[this.clickFile].push({
                    url:response.data.path
                })
                this.$emit("upload", this.formData);
            },

            //移除文件时的钩子函数
            handleFileRemove(file) {
                if (file.status != "success") return this.$message({
                    type: 'warning',
                    message: '删除失败，请重试'
                })

                const index = this.formData[this.clickFile].findIndex((cur) => {
                    return cur.url === file.url
                })
               
                this.formData[this.clickFile].splice(index, 1);
                this.$emit("upload", this.formData);
            },
            
            // 预览图片
            handleFilePreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogImageVisible = true;
                this.previewList = [file.url]
            },

            // 关闭查看器
            closeViewer() {
                this.dialogImageVisible = false
            },

            //文件上传超出
            handleFileExceed(files,fileList){
                console.log(files)
                if(this.limitNum == fileList.length){
                    this.$message.error(`只允许上传${this.limitNum}张图片！`);
                }
            }
        }
    }
</script>
<style lang="less">
    .uploadBox{
        ul{
            li{
                img{
                    object-fit: cover;
                }
            }
        }
    }
</style>