<!--商品添加-->
<template>
    <div class="mainBox" >
        <!--Header-->
        <div class="handleBox flex flex-jc-sb flex-ai-c">
            <el-page-header @back="goBack" :content="title"></el-page-header>
            <el-button type="primary" size="mini" block >保存商品</el-button>
        </div>

        <!--form表单-->
        <div class="formBox shopBox" style="padding-top:20px">
            <!--商品信息-->
            <el-row style="height: calc(100% - 20px);overflow: auto;">
                <el-form ref="formAuth" :rules="formRules" :model="formData" label-width="120px" label-position="right" size="small" class="alertForm">
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="formData.name" placeholder="商品名称" autocomplete="off" :disabled="isShow"></el-input>
                    </el-form-item>
                    <el-form-item label="积分价格" prop="name">
                        <el-input v-model="formData.name" placeholder="运费设置" autocomplete="off" :disabled="isShow"></el-input>
                    </el-form-item>
                    <el-form-item label="商品库存" prop="name">
                        <el-input v-model="formData.name" placeholder="运费设置" autocomplete="off" :disabled="isShow"></el-input>
                    </el-form-item>
                    <el-form-item label="拟定销量" prop="name">
                        <el-input v-model="formData.name" placeholder="运费设置" autocomplete="off" :disabled="isShow"></el-input>
                    </el-form-item>
                    <el-form-item label="商品主图" prop="name">
                        <uploadImage :imageList = "formData.main_pic" :clickFile="'main_pic'" @upload="uploadFile" :mayEdit="isShow"></uploadImage>
                    </el-form-item>
                    <el-form-item label="商品展示图" prop="name">
                        <uploadImage :imageList = "formData.banner_list" :clickFile="'banner_list'" @upload="uploadFile" :mayEdit="isShow"></uploadImage>
                    </el-form-item>
                    <el-form-item  label="商品详情" prop="content" style="width:100%">
                        <editorBar :editorMain = "formData.content" @change="editorChange" :mayEdit="isShow"></editorBar>
                    </el-form-item>
                </el-form>
            </el-row>
        </div>
    </div>
</template>
<script>
    import uploadImage from '@/components/uploadImage';
    import editorBar from '@/components/editorBar';
    export default {
        data() {
            return {
                title:'',//标题
                formRules:{
                    name: [{
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'blur'
                    }],
                    content: [{
                        required: true,
                        message: '请输入商品详情',
                        trigger: 'blur'
                    }],
                },
                formData:{
                    main_pic:[],
                    banner_list:[],
                    content:'',
                },//详情数据

                isShow:false,//详情or编辑
                isBtn:false,
            }
        },
        components:{
            uploadImage, //图片上传
            editorBar, //富文本编辑器
        },
        created() {
            this.init();
        },
        // mounted() {
        //     this.init();
        // },
        methods: {
            //初始化数据
            init: function(){
                this.title = this.$route.query.title;
                this.isShow = JSON.parse(this.$route.query.isShow);
                console.log(this.isShow)
            },
            //上传图片数据
            uploadFile: function(file) {
                this.formData.main_pic = file.main_pic;
                this.formData.banner_list = file.banner_list;
            },

            //删除商品属性
            deleteRow: function(){

            },
            /**
             * 接收富文本子组件输入值
            */
            editorChange: function(value){
                console.log(value)
            },
           
            
            //保存商品
            submit: function(){

            },
            //返回上一页
            goBack() {
                this.$router.go(-1)
            },
        },
    }
</script>